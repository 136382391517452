<template>
<button class="exit" @click="logout">Выйти</button>
<div class="container">
    <div v-if="task == 1" class="task">
        <p>1. Сколько треугольников ты видишь на картинке? 
        <input v-if="ready" v-model="answerInput" v-on:keyup.enter="answer()" placeholder="Введите ответ">
        </p>
        <button v-if="!ready" @click="getReady">Чтобы увидеть картинку, нажми на меня!</button>
        <button v-if="ready" @click="answer()" class="phoneBut">Ответить</button>
        <img v-if="ready" src="../assets/1.png">
    </div>
    <div v-if="task == 2" class="task">
        <p>2. Найди все буквы на чертеже в алфавитном порядке (некоторые буквы пропущены). Выписывай найденные буквы по одной и нажимай после каждой enter <input v-if="ready" v-model="answerInput" v-on:keyup.enter="alongAnswer()" placeholder="Введите ответ"></p>
        <button v-if="ready" @click="task++">Перейти к следующему заданию</button>
        <div class="seconi" v-if="second.length > 0">
            <div v-for="seconi in second" :key="seconi">
                <p>{{ seconi }}</p>
            </div>
        </div>
        <button v-if="!ready" @click="getReady">Чтобы увидеть картинку, нажми на меня!</button>
        <button v-if="ready" @click="alongAnswer()" class="phoneBut">Ответить</button>
        <img v-if="ready" id="sec" src="../assets/22.png">
    </div>
    <div v-if="task == 3" class="task">
        <p>3. Найди все треугольники на картинке и выпиши их названия. Выписывай по три буквы. После каждого введённого треугольника нажимай enter. <input v-if="ready" v-model="answerInput" v-on:keyup.enter="alongAnswer()" placeholder="Введите ответ"></p>
        <div class="seconi" v-if="third.length > 0">
            <div v-for="thir in third" :key="thir.length">
                <p>{{ thir }}</p>
            </div>
        </div>
        <button v-if="ready" @click="task++">Перейти к следующему заданию</button>
        <button v-if="!ready" @click="getReady">Чтобы увидеть картинку, нажми на меня!</button>
        <button v-if="ready" @click="alongAnswer" class="phoneBut">Ответить</button>
        <img v-if="ready" id="sec" src="../assets/2.png">
    </div>
    <div v-if="task >= 4 && task != 24 && task != 25" class="task">
        <div v-if="task == 4">
            <p>4. У треугольников называй цвет, у четырёхугольников - название: трапеция, параллелограмм, прямоугольник, квадрат, ромб, а круги пропускай</p>
            <button v-if="!ready" @click="getReady">Чтобы увидеть картинку, нажми на меня!</button>
            <div v-if="ready" class="triangle-up"></div>
        </div>
        <div v-if="task == 5">
            <p>4. У треугольников называй цвет, у четырёхугольников - название: трапеция, параллелограмм, прямоугольник, квадрат, ромб, а круги пропускай</p>
            <div class="square"></div>
        </div>
        <div v-if="task == 6">
            <p>4. У треугольников называй цвет, у четырёхугольников - название: трапеция, параллелограмм, прямоугольник, квадрат, ромб, а круги пропускай</p>
            <div class="triangle-down"></div>
        </div>
        <div v-if="task == 7">
            <p>4. У треугольников называй цвет, у четырёхугольников - название: трапеция, параллелограмм, прямоугольник, квадрат, ромб, а круги пропускай</p>
            <div class="circle"></div>
        </div>
        <div v-if="task == 8">
            <p>4. У треугольников называй цвет, у четырёхугольников - название: трапеция, параллелограмм, прямоугольник, квадрат, ромб, а круги пропускай</p>
            <div class="rectangle"></div>
        </div>
        <div v-if="task == 9">
            <p>4. У треугольников называй цвет, у четырёхугольников - название: трапеция, параллелограмм, прямоугольник, квадрат, ромб, а круги пропускай</p>
            <div class="parallelogram"></div>
        </div>
        <div v-if="task == 10">
            <p>4. У треугольников называй цвет, у четырёхугольников - название: трапеция, параллелограмм, прямоугольник, квадрат, ромб, а круги пропускай</p>
            <div class="d20"></div>
        </div>
        <div v-if="task == 11">
            <p>4. У треугольников называй цвет, у четырёхугольников - название: трапеция, параллелограмм, прямоугольник, квадрат, ромб, а круги пропускай</p>
            <div class="trapezoid "></div>
        </div>
        <div v-if="task == 12">
            <p>4. У треугольников называй цвет, у четырёхугольников - название: трапеция, параллелограмм, прямоугольник, квадрат, ромб, а круги пропускай</p>
            <div class="triangle-down "></div>
        </div>
        <div v-if="task == 13">
            <p>4. У треугольников называй цвет, у четырёхугольников - название: трапеция, параллелограмм, прямоугольник, квадрат, ромб, а круги пропускай</p>
            <div class="rectangle"></div>
        </div>
        <div v-if="task == 14">
            <p>4. У треугольников называй цвет, у четырёхугольников - название: трапеция, параллелограмм, прямоугольник, квадрат, ромб, а круги пропускай</p>
            <div class="circle"></div>
        </div>
        <div v-if="task == 15">
            <p>4.У треугольников называй цвет, у четырёхугольников - название: трапеция, параллелограмм, прямоугольник, квадрат, ромб, а круги пропускай</p>
            <div class="triangle-up"></div>
        </div>
        <div v-if="task == 16">
            <p>4. У треугольников называй цвет, у четырёхугольников - название: трапеция, параллелограмм, прямоугольник, квадрат, ромб, а круги пропускай</p>
            <div class="square"></div>
        </div>
        <div v-if="task == 17">
            <p>4. У треугольников называй цвет, у четырёхугольников - название: трапеция, параллелограмм, прямоугольник, квадрат, ромб, а круги пропускай</p>
            <div class="d20"></div>
        </div>
        <div v-if="task == 18">
            <p>4. У треугольников называй цвет, у четырёхугольников - название: трапеция, параллелограмм, прямоугольник, квадрат, ромб, а круги пропускай</p>
            <div class="trapezoid"></div>
        </div>
        <div v-if="task == 19">
            <p>4. У треугольников называй цвет, у четырёхугольников - название: трапеция, параллелограмм, прямоугольник, квадрат, ромб, а круги пропускай</p>
            <div class="parallelogram"></div>
        </div>
        <div v-if="task == 20">
            <p>4. У треугольников называй цвет, у четырёхугольников - название: трапеция, параллелограмм, прямоугольник, квадрат, ромб, а круги пропускай</p>
            <div class="circle"></div>
        </div>
        <div v-if="task == 21">
            <p>4. У треугольников называй цвет, у четырёхугольников - название: трапеция, параллелограмм, прямоугольник, квадрат, ромб, а круги пропускай</p>
            <div class="square"></div>
        </div>
        <div v-if="task == 22">
            <p>4. У треугольников называй цвет, у четырёхугольников - название: трапеция, параллелограмм, прямоугольник, квадрат, ромб, а круги пропускай</p>
            <div class="triangle-up"></div>
        </div>
        <div v-if="task == 23">
            <p>4. У треугольников называй цвет, у четырёхугольников - название: трапеция, параллелограмм, прямоугольник, квадрат, ромб, а круги пропускай</p>
            <div class="trapezoid"></div>
        </div>
        <div v-if="ready || task != 4" class="but-rectangles">
            <button @click="answer(1, 'трапеция')">трапеция</button>
            <button @click="answer(2, 'параллелограмм')">параллелограмм</button>
            <button @click="answer(3, 'прямоугольник')">прямоугольник</button>
            <button @click="answer(4, 'квадрат')">квадрат</button>
            <button @click="answer(5, 'ромб')">ромб</button>
            <button @click="answer(6, 'треугольник')">треугольник</button>
            <button @click="answer(7, 'круг')">круг</button>
            <button @click="answer(8, 'пропустить')">пропустить</button>
        </div>
        <div v-if="ready || task != 4" class="but-coolors">
            <button @click="answer(11, 'красный')">красный</button>
            <button @click="answer(22, 'желтый')">желтый</button>
            <button @click="answer(33, 'зеленый')">зеленый</button>
            <button @click="answer(44, 'синий')">синий</button>
        </div>
    </div>
    <div v-if="task == 24" class="task">
        <p>5. Сколько на рисунке незамкнутых окружностей? <input v-if="ready" v-model="answerInput" v-on:keyup.enter="answer(5)" placeholder="Введите ответ"></p>
        <button v-if="!ready" @click="getReady">Чтобы увидеть картинку, нажми на меня!</button>
        <button v-if="ready" @click="answer(5)" class="phoneBut">Ответить</button>
        <img v-if="ready" id="five" src="../assets/5.png">
    </div>
    <div class="complete" v-if="task == 25">
        <p>Поздравляем вы прошли тест, результаты вы сможете узнать у Анны. Тг: @mystery_ann</p>
    </div>
</div>
</template>

<script>
export default {
    name:'Task-Page',
    data() {
        return {
            currentTotalTimer: 0,
            currentTimeBetweenAnswer: 0,
            timeBetweenAnswer: [],
            thirdTime: [],
            time: null,
            timer: null,
            answerInput: '',
            first: "13",
            second: [],
            secondTime: [],
            secondAnswered: [],
            third: [],
            thirteen: '6',
            task: 1,
            rightAnswer: 0,
            ready: false,
            answered: [],
            thirdAnswered: []
        }
    },
    created() {
        this.startTotalTimer()
    },
    methods: {
        getReady() {
            this.startTimeBetweenAnswer()
            this.ready = true
        },
        logout() {
            localStorage.removeItem("admin")
            localStorage.removeItem("username")
            this.$router.push('/')
        },
        alongAnswer() {
            if(this.task == 2) {
                if(this.second.length != 15) {
                    this.second.push(this.answerInput)
                    console.log(this.second)
                    this.secondAnswered.push(this.answerInput)
                    this.answerInput = ''
                    this.secondTime.push(this.currentTimeBetweenAnswer)
                    this.currentTimeBetweenAnswer = 0
                    console.log(this.timeBetweenAnswer)
                }
                else {
                    this.second = []
                    this.secondAnswered.push(this.answerInput)
                    this.answerInput = ''
                    this.task++
                    this.stopTimeBetweenAnswer()
                    this.ready = false

                    this.secondTime.push(this.currentTimeBetweenAnswer)
                    this.currentTimeBetweenAnswer = 0
                    console.log(this.timeBetweenAnswer)
                    return console.log("stoped")
                }
            }
            if(this.task == 3) {
                if(this.third.length != 9) {
                    this.third.push(this.answerInput)
                    this.thirdAnswered.push(this.answerInput)
                    this.answerInput = ''

                    this.thirdTime.push(this.currentTimeBetweenAnswer)
                    this.currentTimeBetweenAnswer = 0
                    console.log(this.thirdTime)
                }
                else {
                    this.thirdAnswered.push(this.answerInput)
                    this.task++
                    this.stopTimeBetweenAnswer()
                    this.ready = false

                    this.thirdTime.push(this.currentTimeBetweenAnswer)
                    this.currentTimeBetweenAnswer = 0
                    console.log(this.thirdTime)
                }
            }
            
            console.log(this.answered)
            console.log(this.thirdAnswered)
        },
        answer(id, text) {
            if(this.task == 1 && this.answerInput == this.first) {
                this.rightAnswer++
            }
            if(id == 1 && this.task == 11) {
                this.rightAnswer++
            }
            if(id == 2 && this.task == 9) {
                this.rightAnswer++
            }
            if(id == 3 && this.task == 8) {
                this.rightAnswer++
            }
            if(id == 4 && this.task == 5) {
                this.rightAnswer++
            }
            if(id == 5 && this.task == 10) {
                this.rightAnswer++
            }
            if(id == 7 && this.task == 7) {
                this.rightAnswer++
            }
            if(id == 11 && this.task == 4) {
                this.rightAnswer++
            }
            if(id == 22 && this.task == 12) {
                this.rightAnswer++
            }
            if(id == 3 && this.task == 13) {
                this.rightAnswer++
            }
            if(id == 7 && this.task == 14) {
                this.rightAnswer++
            }
            if(id == 11 && this.task == 15) {
                this.rightAnswer++
            }
            if(id == 4 && this.task == 16) {
                this.rightAnswer++
            }
            if(id == 5 && this.task == 17) {
                this.rightAnswer++
            }
            if(id == 1 && this.task == 18) {
                this.rightAnswer++
            }
            if(id == 2 && this.task == 19) {
                this.rightAnswer++
            }
            if(id == 7 && this.task == 20) {
                this.rightAnswer++
            }
            if(id == 4 && this.task == 21) {
                this.rightAnswer++
            }
            if(id == 11 && this.task == 22) {
                this.rightAnswer++
            }
            if(id == 1 && this.task== 23) {
                this.rightAnswer++
            }
            if(id == 22 && this.task == 6) {
                this.rightAnswer++
            }
            if(id == 8 && this.task == 7) {
                this.rightAnswer++
            }
            if(id == 8 && this.task == 14) {
                this.rightAnswer++
            }
            if(id == 8 && this.task == 20) {
                this.rightAnswer++
            }           
            if(this.task == 24 && this.answerInput == this.thirteen) {
                this.rightAnswer++
            }
            if(!text) {
                this.answered.push(this.answerInput)
                this.answerInput = ''
            }
            this.timeBetweenAnswer.push(this.currentTimeBetweenAnswer)
            this.currentTimeBetweenAnswer = 0
            this.task++
            if(this.task < 4) {
                this.stopTimeBetweenAnswer()
                this.ready = false
            }
            console.log(this.rightAnswer)
            console.log(this.timeBetweenAnswer)
            if(this.task == 25) {
                this.sendData()
            }
            console.log(this.answered)

            if(text) {
                this.answered.push(text)
            }
        },
        startTimeBetweenAnswer() {
            this.time = setInterval(() => {
                this.currentTimeBetweenAnswer+=0.1
            }, 100)
        },
        stopTimeBetweenAnswer() {
            clearTimeout(this.time)
        },
        startTotalTimer() {
            this.timer = setInterval(() => {
                this.currentTotalTimer++
            }, 1000)
        },
        stopTotalTimer() {
            clearTimeout(this.timer)
        },
        sendData() {
            var percent = (this.rightAnswer / 21) * 100
            percent = Math.round(percent)
            var mathTimeBetweenAnswer = []
            this.timeBetweenAnswer.forEach(el => mathTimeBetweenAnswer.push(Math.round(el * 100) / 100))
            var mathThirdTime = []
            this.thirdTime.forEach(el => mathThirdTime.push(Math.round(el * 100) / 100))

            const url = "/api/answer/newstatistic"
            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({timeBetweenAnswer: mathTimeBetweenAnswer, third: mathThirdTime, username: localStorage.getItem("username"), totalTime: this.currentTotalTimer, rightAnswer: percent, answered: this.answered, thirdAnswered: this.thirdAnswered, secondAnswered: this.secondAnswered, secondTime: this.secondTime})
            })
            .then(res => res.json())
            .then(data => {
                console.log(data.message)
            })
        }
    }
}
</script>

<style scoped>

.complete {
    background-color: #F9F5FF;
    padding: 10px;
    border-radius: 10px;
    margin: 350px auto;
}

.phoneBut {
    display: none;
    position: absolute;
}
.seconi {
    width: 80%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    margin: 0 auto;
}

.seconi > div {
    animation: show 1s;
}

@keyframes show {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@media screen and (max-width: 900px) {
    .task {
        width: 350px !important;
    }

    #sec {
        height: 300px !important;
        width: 350px !important;
    }

    .phoneBut {
        display: initial;
        position: initial;
        background-color: #8aff8a;
        border: 1px solid black;
    }

    .but-rectangles {
        width: 310px;
        grid-template-columns: 100px 100px 100px;
        grid-column-gap: 30px;
        grid-row-gap: 1em;
        display: grid !important;
    }

    .but-rectangles > button {
        width: 120px;
        font-size: 13px;
    }

    .but-coolors {
        width: 310px;
        grid-template-columns: 80px 80px;
        grid-column-gap: 50px;
        grid-row-gap: 1em;
        display: grid;
    }

    .but-coolors > button {
        width: 120px;
        font-size: 13px;
    }

    #five {
        margin-top: 30px;
        width: 350px !important;
        height: 200px !important;
    }
}

.exit {
    background-color: rgb(168, 57, 57);

    border: 1px solid black;
}
.exit:hover {
    background-color: #8aff8a;
    transition: linear 0.2s;
}

button {
    padding: 10px;
    font-size: 18px;
    font-family: 'Josefin Sans', sans-serif;
    margin-left: 10px;
}

.but-rectangles {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    margin-bottom: 50px;
    margin-top: 50px;
}

.trapezoid {
	border-bottom: 50px solid red;
	border-left: 25px solid transparent;
	border-right: 25px solid transparent;
	height: 0;
	width: 125px;
    margin: 0 auto;
}

.d20 {
margin-bottom: 20px; 
width: 0; 
height: 0;
border: 50px solid transparent;
border-bottom: 70px solid #1100ce;
position: relative; 
top: -50px;
margin: 0 auto;
}
.d20:after {
content: "";
width: 0; 
height: 0;
position: absolute; 
left: -50px; 
top: 70px;
border: 50px solid transparent;
border-top: 70px solid #1100ce;
}
.triangle-up {
	width: 0;
	height: 0;
	border-left: 25px solid transparent;
	border-right: 25px solid transparent;
	border-bottom: 50px solid red;
    margin: 0 auto;
}

.rectangle {
  height: 50px;
  width: 100px;
  background-color: #00bd39;
  margin: 0 auto;
}

.circle {
  height: 50px;
  width: 50px;
  background-color: #FFD300;
  border-radius: 50%;
  margin: 0 auto;
}

.parallelogram {
	width: 100px;
	height: 50px;
	transform: skew(20deg);
	background: blue;
    margin: 0 auto;
}

.triangle-down {
	width: 0;
	height: 0;
	border-left: 25px solid transparent;
	border-right: 25px solid transparent;
	border-top: 50px solid #FFD300;
    margin: 0 auto;
}

.square {
  height: 50px;
  width: 50px;
  background-color: green;
  margin: 0 auto;
}


.container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.container input {
    margin-left: 10px;

    padding: 10px;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 18px;

    outline: none;
}

#sec {
    width: 400px;
    height: 300px;
}

#thir {
    width: 600px;
    height: 300px;
}

#five {
    width: 800px;
    height: 400px;
}

.task {
    background-color: #F9F5FF;
    padding: 20px;
    border-radius: 10px;
    margin-top: 50px;
    margin-bottom: 50px;
    width: 960px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}
</style>