import { createApp } from 'vue'
import App from './App.vue'
import { createRouter, createWebHistory } from 'vue-router'

import HomePage from './components/HomePage.vue'
import TaskPage from './components/TaskPage.vue'
import AdminPage from './components/AdminPage.vue'
import RefPage from './components/RefPage.vue'

const router = createRouter({

    routes :
    [
        {
            path: '/',
            name: 'Home',
            component: HomePage
        },
        {
            path: '/task',
            name: 'Task',
            component: TaskPage
        },
        {
            path: '/results',
            name: 'results',
            component: AdminPage
        },
        {
            path: '/ref/:decodeUser',
            name: 'ref',
            component: RefPage
        }
    ],
    history: createWebHistory()
})

router.beforeEach((to, from, next) => {
    if(localStorage.getItem("username") && to.name == 'Home') {
        next({name: 'Task'})
    }
    if (!localStorage.getItem("admin") && to.name == 'results') {
        next({name: 'Home'})
    }
    if (!localStorage.getItem("username") && to.name == 'Task')
    {
        next({ name: 'Home' })
    } 
    else 
    {
        next()
    }
})

const app = createApp(App)
app.use(router)
app.mount('#app')
