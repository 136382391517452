<template>
    <div>
        <p>{{ id.id }}</p>
    </div>
</template>

<script>
export default {
    name: 'ref-page',
    data() {
        return {
            id: 'none'
        }
    },
    created() {
        const path = window.location.href
        const decodeUser = path.substring(path.lastIndexOf('/') + 1)
        const encodeUser = atob(decodeUser)
        this.id = JSON.parse(encodeUser)
        if(this.id.id == 1) {
            localStorage.setItem("ref", "Анны")
        }
        if(this.id.id == 2) {
            localStorage.setItem("ref", "Алексея")
        }
        if(this.id.id == 3) {
            localStorage.setItem("ref", "Константина")
        }
        if(this.id.id == 4) {
            localStorage.setItem("ref", "Михаила")
        }

        this.$router.push('/')
    }
}
</script>

<style scoped>
    div {
        margin: 0 auto;
        padding: 10px;
        border-radius: 10px;
        background-color: azure;
    }
</style>