<template>
<div class="container">
    <div class="block">
        <h1>Добро пожаловать на сайт Спортивной математички</h1>
        <div class="text-field">
            <input type="text" v-model="username" v-on:keyup.enter="alarm" placeholder="Введите ваше имя">
        </div>
        <button @click="alarm">Готово</button>
    </div>
    <div v-if="alarmFlag" class="back-alarm">
    </div>
    <div v-if="alarmFlag" class="alarm">
            <p>Как только вы попадете на страницу с вопросами, запустится таймер, который будет влиять на итоговый результат. В некоторых заданиях потребуется вводить ответ в поле для ответа. Для этого как ввели ответ, нажимайте enter</p>
            <div class="buts">
                <button @click="login">Готово</button>
                <button @click="alarmFlag = false">Назад</button>
            </div>
        </div>
</div>
</template>

<script>
export default {
    name: 'HomePage',
    data() {
        return {
            username: '',
            alarmFlag: false,
        }
    },
    methods: {
        login() {
            if(localStorage.getItem("ref")) {
                const url = "/api/auth/login"
                fetch(url, {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({username: this.username, member: localStorage.getItem("ref")})
                })
                .then(res => res.json())
                .then(data => {
                    console.log(data)
                    localStorage.setItem("username", this.username)
                    this.$router.push('Task')
                })
            } else {
                alert("Вы должны перейти по ссылке типа quiz.vzotov.ru/(кто вас пригласил). Возможно, вы сможете ее найти в чате с вашим преподавателем")
            }   
        },
        alarm() {
            if(this.username == 'admin') {
                localStorage.setItem('admin', true)
                this.$router.push('/results')
            }
            else {
                this.alarmFlag = true
            }
        }
    }
}

</script>

<style scoped>

.buts {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
}
.back-alarm {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.616);
    height: 100%;
    width: 100%;
}

.alarm {
    position: absolute;
    margin: 0 auto;
    
    background-color: #F9F5FF;
    padding: 20px;
    border-radius: 10px;
}

.alarm > button {
    margin-left: 20px;
}
.container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    height: 100%;
}

.block {
    background-color: #F9F5FF;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.498);

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

button {
    width: 524px;
    padding: 10px;
    font-size: 18px;
    border: none;

    margin-top: 10px;

    outline: none;
    color: white;
    background: linear-gradient(to left, rgb(27, 89, 159) 50%, #60992D 50%) right;
    background-size: 200%;
    transition: .3s ease-out;
}

button:hover {
    background-position: left;
}

input {
    width: 500px;
    padding: 10px;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 18px;

    outline: none;
}
@media screen and (max-width: 900px) {

    .block {
        width: 350px;
    }
    
    input {
        width: 330px;
    }

    button {
        width: 350px;
    }

    .alarm {
        width: 350px;
    }
}

</style>