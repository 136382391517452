<template>
  <router-view/>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans&family=Nunito&display=swap');

@media screen and (min-width: 2560px) {
  html,body, #app {
    width: 2560px !important;
  }
}

@media screen and (max-width: 412px) {
  html,body, #app {
    width: 412px;
  }
}

@media screen and (min-width: 1920px) {
  html,body, #app {
    width: 1920px;
  }
}

html,body, #app{
  height: 100%;
  padding: 0px;
  margin: 0px;
}

body {
  background: #4C4B63;
}

p {
  font-family: 'Josefin Sans', sans-serif;
  font-size: 20px;
}

h1 {
  font-family: 'Nunito', sans-serif;
  letter-spacing: 2px;
}
</style>
