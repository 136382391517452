<template>
    <div @click="hideAverage" v-if="backAverage" class="back-average">
    </div>
        <button class="exit" @click="logout">Выйти</button>
        <button class="average-but" @click="showAverage">Среднее значение</button>
        <div class="main1">
            <div class="container">
                <div class="head">
                    <button @click="getStatistic('latest')">Обновить</button>
                    <p>Отсортировать:</p>
                    <button @click="getStatistic('top')">Наилучшему результату</button>
                    <button @click="getStatistic('latest')">Недавно завершенным</button>
                    <input v-model="username" v-on:keyup.enter="getStatistic(`username=${username}`)" placeholder="Введите имя пользователя">
                    <button @click="getStatistic(`username=${username}`)" class="phone-but">Найти</button>
                </div>
                <hr>
                <div class="main">
                    <div class="main-item" v-for="statistic in statistics" :key="statistic._id">
                        <div>
                            <p>Выполнил: {{ statistic.userUId.username }}</p>
                            <p>От {{ statistic.userUId.member }}</p>
                            <p>Всего времени затрачено на выполнение теста: {{ statistic.totalTime }} сек.</p>
                            <p>Правильно выполненых заданий: {{ statistic.percentRightAnswer }} %</p>
                        </div>
                        <hr>
                        <p>Время между ответами</p>
                        <div>
                            <p>1. {{ statistic.timeBetweenAnswer[0] }}</p>
                            <p v-if="statistic.second && statistic.second.length > 0">2. {{ statistic.second }} Суммарное время {{ Math.round(statistic.second.reduce((acc, value) => acc + value) * 100) / 100 }}</p>
                            <p v-else>2. Времени нету, потому-что это старый пользователь</p>
                            <p>3. {{ statistic.third }} Суммарное время {{ Math.round(statistic.third.reduce((acc, value) => acc + value) * 100) / 100 }}</p>
                            <p>4. {{ statistic.timeBetweenAnswer.slice(1,21) }} Суммарное время {{ Math.round(statistic.timeBetweenAnswer.slice(17,36).reduce((acc, value) => acc + value) * 100) / 100 }}</p>
                            <p>5. {{ statistic.timeBetweenAnswer[21] }}</p>
                        </div>
                        <hr>
                        Ответы
                        <div>
                            <p>1. {{ statistic.answered[0] }}</p>
                            <p>2. {{ statistic.secondAnswered }}</p>
                            <p>3. {{ statistic.thirdAnswered }}</p>
                            <p>4. {{ statistic.answered.slice(1, 21) }}</p>
                            <p>5. {{ statistic.answered[21] }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
<div v-if="backAverage || widht >= 1900" class="average">
        <p>Среднее время между ответами: {{ averageTba }}</p>
        <p>Среднее значение правильно выполненых заданий: {{ averagePra }} %</p>
        <p>Среднее время затраченое на тест: {{ averageTt }}</p>
    </div>
</template>

<script>
export default {
    name: 'AdminPage',
    data() {
        return {
            backAverage: false,
            widht: Number,
            username: '',
            statistics: [],
            averageTba: '',
            averagePra: '',
            averageTt: ''
        }
    },
    created() {
        this.getStatistic('latest')
        this.getAverage()
        this.widht = window.screen.width
    },
    methods: {
        getStatistic(query) {
            const url = `/api/answer/getstatistics?${query}`
            fetch(url, {
                method: 'GET',
            })
            .then(res => res.json())
            .then(data => {
                this.statistics = data.statistics
                console.log(this.statistics)
            })
        },
        showAverage() {
            this.backAverage = true
        },
        hideAverage() {
            this.backAverage = false
        },
        getAverage() {
            const url = '/api/answer/getaverage'
            fetch(url, {
                method: 'GET',
            })
            .then(res => res.json())
            .then(data => {
                this.averageTba = data.averageTimeBetweenAnswer
                this.averagePra = data.averagePercentRightAnswer
                this.averageTt = data.averageTotalTime
            })
        },
        logout() {
            localStorage.removeItem("admin")
            localStorage.removeItem("username")
            this.$router.push('/')
        }
    }
}
</script>

<style scoped>

input {
    padding: 6px;
}

.main1 {
    display: flex;
    justify-content: center;
    align-items: center;
}

.average {
    background-color: #F9F5FF;
    padding: 20px;
    border-radius: 5px;
    position: absolute;
    right: 0;
    top: 400px;
}

.exit {
    background-color: rgb(168, 57, 57);
}
.exit:hover {
    background-color: #8aff8a;
    transition: linear 0.2s;
}
hr {
    height: 1px;
        background-color: black;
        border: none;
}
.main-item > div:first-child {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
}

.main {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.main-item:hover {
    background-color: #ac66f7;
    width: 98%;
    transition: linear 0.1s;
}

.main-item {
    background-color: rgb(133, 113, 197);
    padding: 5px;
    border-radius: 10px;
    margin-bottom: 20px;
    margin-top: 20px;
    width: 1300px;
    transition: linear 0.1s;
}

.main-item > p{
    font-size: 20px;
}
.container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #F9F5FF;
    padding: 10px;
    border-radius: 10px;
    width: 1400px;
    margin: 100px auto;
}

.head {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    width: 100%;
}

button:hover {
    background-color: #b98aff;
    transition: linear 0.2s;
}

button {
    padding: 5px;
    width: 300px;
    font-size: 18px;
    background-color: #f2edf9;
    outline: none;
    transition: linear 0.2s;
    border: 0.1px solid black;
}
hr {
    width: 100%;
}

.buttons {
    display: none;
    position: absolute;
}

.average-but {
    display: none;
    position: absolute;
}

.back-average {
    display: none;
    position: absolute;
}

.phone-but {
    display: none;
    position: absolute;
}

@media screen and (max-width: 1900px) {
    .average-but {
        display: initial;
        position: initial;
    }

    .back-average {
        display: initial;
        background-color: rgba(0, 0, 0, 0.516);
        width: 100%;
        height: 100%;
    }

    .average {
        right: 100px;
        left: 100px;
    }

    .container {
        width: 95% !important;
        margin: 100px 0 0 0 !important;
    }

    .main-item {
        width: 100% !important;
    }

    input {
        width: 180px;
    }

    p {
        font-size: 15px;
    }

    button {
        width: 180px;
        font-size: 15px;
    }
}

@media screen and (max-width: 600px) {
    .main-item {
        width: 320px;
    }

    .main-item div:first-child {
        flex-direction: column;
        align-items: flex-start;
    }

    .main-item div > p {
        font-size: 15px;
    }

    .main-item > p {
        font-size: 15px;
    }

    .phone-but {
        display: initial;
        position: initial;
        margin-top: 15px;
    }

    .back-average {
        display: initial;
        background-color: rgba(0, 0, 0, 0.516);
        width: 100%;
        height: 100%;
    }

    .average-but {
        display: initial;
        position: initial;
    }
    .container {
        width: 350px;
        margin: 0 0 0 0 !important;
    }

    .head {
        flex-direction: column;
    }

    button {
        width: 180px;
        font-size: 15px;
        margin-bottom: 20px;
    }

    .average {
        width: 350px;
        left: 0;
        right: 0;
    }

    input {
        width: 180px;
    }
}

@media screen and (max-width: 1920px) {
    .container {
        width: 1150px;
        margin-left: 150px;
    }

    button {
        width: 250px;
    }
}
</style>